<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column flex-container">
            <b-row class="flex-center">
                <template v-if="code === '0000'">
                    <b-alert show variant="success" class="col-12">
                        <h4 class="alert-heading">Well done!</h4>
                        <p>
                            Your order was successful!
                        </p>
                    </b-alert>
                    <router-link to="/" class="dark-link">
                        <h4>&lt; Back to homepage</h4>
                    </router-link>
                </template>
                <template v-else>
                    <b-alert show variant="danger" class="col-12">
                        {{message}}
                    </b-alert>
                    <router-link to="/cart" class="dark-link">
                        <h4>&lt; Back to cart</h4>
                    </router-link>
                </template>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'result',
        computed: {
            code: function () {
                return this.$route.params.code;
            },
            message: function () {
                let message = '';
                switch (this.code) {
                    case '1001':
                        message = 'Error: Card expired';
                        break;
                    case '1002':
                    case '1003':
                        message = "Error: Card suspended";
                        break;
                    case '1004':
                        message = "Error: Card stolen";
                        break;
                    case '1005':
                        message = "Error: Card lost";
                        break;
                    case '1011':
                        message = "Error: Card not found";
                        break;
                    case '1012':
                        message = "Error: Cardholder not found";
                        break;
                    case '1014':
                        message = "Error: Account not found";
                        break;
                    case '1016':
                        message = "Error: Not sufficient funds";
                        break;
                    case '1024':
                        message = "Error: Installments are not allowed for this card";
                        break;
                    case '1026':
                        message = "Error: Installments are not allowed for non ZABA cards";
                        break;
                    case '1050':
                        message = "Error: Transaction declined";
                        break;
                    case '1804':
                        message = "Error: Invalid card number";
                        break;
                    default:
                        message = 'Error: Internal bank error';
                        break;
                }
                return message;
            }
        },
        mounted() {
            if(this.code === '0000') {
                this.$store.dispatch('clearCart');
            }
        }
    }

</script>

<style lang="scss">

</style>
