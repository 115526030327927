<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column cart py-5">
            <b-row>
                <h1 class="col-12">
                    Invoking 3-D secure form, please wait ...
                </h1>
                <form name="form" :action="secure['acs-url']" method="post">
                    <input type="hidden" name="PaReq" :value="secure.pareq">
                    <input type="hidden" name="TermUrl" value="https://api.amplius.tech/secure-3d.php">
                    <input type="hidden" name="MD" :value="secure['authenticity-token']">
                    <noscript>
                        <p>Please click</p><input id="to-asc-button" type="submit">
                    </noscript>
                </form>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'secure-3d',
        data () {
            return {
            }
        },
        computed: {
          secure: function () {
              return this.$store.getters.getSecure;
          }
        },
        mounted() {
            window.form.submit();
        }
    }

</script>

<style lang="scss">

</style>
