<template>
    <div class="cart-info">
        <b-row class="cart-item flex-center" v-for="item in cart" :key="item.key">
            <b-col cols="4">
                <img :src="require('../assets/img/' + item.image)" class="img-fluid">
                <div class="item-count">{{item.count}}</div>
            </b-col>
            <b-col cols="6" class="flex-center flex-column">
                {{item.name}}<br/>
                <div v-if="item.size">
                    Size: {{item.size}}
                </div>
                <div v-else-if="item.color">
                    Color: {{item.color}}
                </div>
            </b-col>
            <b-col cols="2">
                {{item.price * 0.01 * item.count}}€
            </b-col>
        </b-row>
        <b-form v-on:submit.prevent="false" class="row mx-0">
            <div class="col-12 col-md-6 flex-center">
                <b-form-input
                        class="mt-0"
                        name="discount"
                        disabled
                        placeholder="Discount code">
                </b-form-input>
            </div>
            <div class="col-12 col-md-6 pl-md-2 mt-2 mt-md-0">
                <button class="amplius-btn dark-btn">
                    Apply
                </button>
            </div>
        </b-form>
        <div class="row">
            <b-col class="col-12 d-flex justify-content-between">
                <div>Subtotal</div>
                <div>{{price * 0.01}}€</div>
            </b-col>
            <b-col class="col-12 d-flex justify-content-between">
                <div>Discount</div>
                <div>-</div>
            </b-col>
            <b-col class="col-12 d-flex justify-content-between">
                <div>Shipping</div>
                <div>-</div>
            </b-col>
            <b-col class="col-12 d-flex justify-content-between">
                <div>Total</div>
                <div>{{price * 0.01}}€</div>
            </b-col>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'cart-info',
        props: {
            cart:  null,
            price: null
        },
        data () {
            return {
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cart-info {
        > * {
            margin-top: 20px;
        }
    }

    .cart-item {
        .item-count {
            position: absolute;
            top: 0;
            right: 15px;
            width: 24px;
            height: 24px;
            color: white;
            line-height: 24px;
            background: #37afe1;
            border-radius: 50%;
        }
        img {
            max-height: 60px;
        }
    }

</style>
