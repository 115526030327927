<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column cart py-5">
            <b-row>
                <b-col cols="12" md="7">
                    <h4>Your cart</h4>
                    <b-table striped stacked="md" :items="cart" :fields="dataFields">
                        <template slot="image_slot" slot-scope="data" class="item_image">
                            <div class="item_image">
                                <img :src="require('../../assets/img/' + data.item.image)" class="img-fluid">
                            </div>
                        </template>
                        <template slot="count" slot-scope="data">
                            <div class="row counter">
                                <div class="count-action"
                                     @click="decrease(data.item.key)">
                                    -
                                </div>
                                <div class="count-num">
                                   {{data.item.count}}
                                </div>
                                <div class="count-action"
                                     @click="increase(data.item.key)">
                                    +
                                </div>
                            </div>
                        </template>
                        <template slot="price" slot-scope="data">
                            {{data.item.price * 0.01}}€
                        </template>
                        <template slot="customize" slot-scope="data">
                            <div v-if="data.item.size">
                                Size: {{data.item.size}}
                            </div>
                            <div v-else-if="data.item.color">
                                Color: {{data.item.color}}
                            </div>
                        </template>
                        <template slot="actions" slot-scope="data">
                            <div class="flex-center" @click="remove(data.item.key)">
                                <icon name="times" v-b-tooltip.hover title="Remove from cart" class="cart_action"></icon>
                            </div>
                        </template>
                    </b-table>
                    <div v-if="price === 0">
                        Your cart is empty!
                    </div>
                </b-col>
                <b-col cols="12" md="5">
                    <router-link to="/" class="dark-link">
                        <h4>Continue shopping &gt;</h4>
                    </router-link>
                    <h4 class="d-flex justify-content-between">
                        <div>SUBTOTAL</div>
                        <div>{{price * 0.01}}€</div>
                    </h4>
                    <router-link v-if="price !== 0" to="/cart/customer">
                        <button class="amplius-btn dark-btn checkout">
                            CHECKOUT
                        </button>
                    </router-link>
                    <div class="text-left">
                        The purchaser is responsible for additional costs such
                        as duties, taxes, and customs clearance fees
                        on delivery.
                    </div>
                    <cards></cards>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import 'vue-awesome/icons/times'
    import Icon from 'vue-awesome/components/Icon';
    import Cards from '../Cards';

    export default {
        name: 'cart',
        components: {
            Icon,
            Cards },
        data () {
            return {
                dataFields: [
                    {
                        key: 'image_slot',
                        label: ''
                    },
                    'name',
                    'price',
                    'customize',
                    'count',
                    'actions'
                ]
            }
        },
        computed: {
            cart: function () {
                return this.$store.getters.getCart.filter(item => {
                    return item.count > 0
                });
            },
            price() {
                return this.$store.getters.totalPrice;
            }
        },
        methods: {
            increase: function (item) {
                this.$store.dispatch('addToCart', item);
            },
            decrease: function (item) {
                this.$store.dispatch('decreaseItem', item);
            },
            remove: function(item) {
                this.$store.dispatch('removeFromCart', item);
            }
        }
    }
</script>

<style lang="scss" scoped>

    .counter {
        > div {
            flex: 1;
        }
    }

    .count-num {
        border: 1px solid #4c4c4c;
        border-radius: 3px;
    }

    .count-action {
        cursor: pointer;
        &:hover {
            color: rgba(76, 76, 76, 0.5)!important;
        }
    }

    .checkout {
        margin: 20px 0;
    }
</style>
