import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueScrollTo from 'vue-scrollto'

var options = {
    container: "body",
    duration: 1000,
    easing: "ease",
    offset: -69,
    cancelable: true,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
};

Vue.use(VueScrollTo, options);

import Flickity from 'vue-flickity';
import 'flickity/dist/flickity.css';
Vue.component('flickity', Flickity);

import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

// Axios
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
