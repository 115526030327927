<template>

    <div id="app">
        <nav id="myNavbar" v-bind:class="{ scrolled : isScrolled}" class="container-fluid fixed-top">
            <div class="container">
                <a href="/" target="_self">
                    <img id="logo" class="img-fluid" src="./assets/img/logo.png"/>
                </a>
                <div id="toggle" v-on:click="toggleNav" v-bind:class="{ expanded: isExpanded }">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul v-bind:class="{ expanded: isExpanded }">
                    <li v-for="(item, index) in navItems" :class="{active : activeNav === index}" @click="activeNav = index" :key="item.name">
                        <router-link :to="'/' + item.href" @click="scrollElement(item.href)">{{ item.name }}</router-link>
                    </li>
                    <li :class="{active : activeNav === 5}" @click="activeNav = 5">
                        <router-link to="/about">About</router-link>
                    </li>
                    <li :class="{active : activeNav === 6}" @click="activeNav = 6">
                        <router-link to="/cart"><icon name="shopping-cart"></icon>&nbsp;({{cartCount}})</router-link>
                    </li>
                </ul>
            </div>
        </nav>

        <router-view class="amplius-content"></router-view>

        <b-container fluid class="amplius-bg" id="contact">
            <b-container>
                <b-row class="pt-4">
                    <b-col md="6" class="flex-center flex-column my-2">
                        <div>
                            <img class="img-fluid mb-3" src="./assets/img/logo2.png">
                        </div>
                        <p>Amplius electronics d.o.o.<br/>
                            Kralja Petra Krešimira IV b.b.<br/>
                            88000 Mostar<br/>
                            Bosnia and Herzegovina</p>
                        <p class="m-0">info@amplius.tech</p>
                        <p class="m-0">sales@amplius.tech</p>
                        <a href="#">www.amplius.tech</a>
                    </b-col>
                    <b-col md="6" class="flex-center flex-column my-2">
                        <h5>Signup today for free and be the<br/>first to get notified on new updates</h5>
                        <form action="https://tech.us12.list-manage.com/subscribe/post?u=181339bd6f2eae1fb76103673&amp;id=375a96663b"
                              class="subscribe-form flex-center flex-column align-items-center"
                              method="post">
                            <input type="email" name="EMAIL" placeholder="E-mail address" id="mce-EMAIL" required>
                            <span class="bar"></span>
                            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                            <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                <input type="text" name="b_181339bd6f2eae1fb76103673_375a96663b" tabindex="-1" value="">
                            </div>
                            <button type="submit" name="subscribe" id="mc-embedded-subscribe" class="amplius-btn mt-2">SUBSCRIBE</button>
                        </form>
                    </b-col>
                </b-row>
                <b-row id="footer" class="p-0 m-0">
                    <b-col md="6" class="my-2">
                        <a href="https://api.amplius.tech/termsofservices.html" target="_blank">Terms of service</a>
                        <a href="https://api.amplius.tech/privacypolicy.html" target="_blank">Privacy policy</a>
                        <a href="https://api.amplius.tech/securitypolicy.html" target="_blank">Security policy</a>
                        <a href="https://api.amplius.tech/returns.html" target="_blank">Returns &amp; Replacements</a>
                    </b-col>
                    <b-col md="6" class="my-2">
                        Amplius electronics 2018 &copy; All Rights Reserved.<br/>Various trademarks held by their respective owners.
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

    </div>

</template>

<script>
    import 'vue-awesome/icons/shopping-cart'
    import Icon from 'vue-awesome/components/Icon'



    export default {
        components: { Icon },
        data () {
            return {
                isExpanded: false,
                activeNav: 0,
                isScrolled: false,
                navItems: [
                    {name: 'Home', href: ''},
                    {name: 'Features', href: 'features'},
                    {name: 'App', href: 'application'},
                    {name: 'Preorder', href: 'preorder-items'},
                    {name: 'Contact', href: 'contact'}
                ]
            }
        },
        computed: {
            cartCount: function () {
                return this.$store.getters.countCart;
            }
        },
        methods: {
            toggleNav: function (e) {
                e.preventDefault();
                event.stopPropagation();
                this.isExpanded = !this.isExpanded;
            },
            checkScroll () {
                this.isScrolled = window.scrollY > 20
            },
            scrollElement(el) {
                this.$scrollTo('#' + el);
            }
        },
        mounted () {
            window.addEventListener('scroll', this.checkScroll);
            let self = this;
            window.addEventListener('click', function (e) {
                if (e.target.id !== 'toggle') {
                    self.isExpanded = false;
                }
            });
        },
        watch: {
            '$route' (to){
                this.$nextTick(() => {
                    if (to.path !== '/about' && !to.path.match('/cart')) {
                        if (to.path === '/') {
                            this.scrollElement('home');
                        } else {
                            this.scrollElement(this.$route.params.scroll);
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    @import "./assets/sass/main";
</style>
