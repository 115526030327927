<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column flex-container">
            <b-row>
                <b-alert show variant="danger" v-for="error in errors" class="col-12 error" :key="error">
                    {{error}}
                </b-alert>
                <router-link to="/cart" class="dark-link">
                    <h4>&lt; Back to cart</h4>
                </router-link>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'error',
        data () {
            return {
            }
        },
        computed: {
          errors: function () {
              return this.$store.getters.getErrors;
          }
        },
        mounted() {
        }
    }

</script>

<style lang="scss">

</style>
