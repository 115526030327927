<template>
    <div>
        <b-container fluid class="about-bg p-0">
            <img src="../assets/img/about-bg.png" class="img-fluid w-100">
        </b-container>

        <b-container class="p-4">
            <h2>About company</h2>
            <p>
                Each and every one of our team members represents the spirit of our company: success-driven and committed
                people who care a lot about „getting it done“ and building relationships with others.<br/>
                We, the people of Amplius electronics, are dedicated team players who bring energy,
                ideas and pride to our work.<br/>
                We can see that Amplius electronics represents a strong competition, and we strive to create an
                environment where smart, motivated and creative people can become successful.<br/>
                While all of the above may sound rough, other people say we are down-to-earth, approachable and
                fun to be around!<br/>
                We work hard on our personal development, and luckily are supported by many others on our journey!<br/>
                We do work hard- but we also enjoy what we do.<br/>
            </p>
        </b-container>

        <b-container>
            <b-row>
                <div class="col-12 col-md-4 my-4 team-member flex-center flex-column">
                    <div>
                        <img src="../assets/img/team/lukito.png" class="img-fluid">
                    </div>
                    <h4>Luka Vučina</h4>
                    <h5>Hardware Developer</h5>
                    <div class="team-contact flex-center">
                        <a href="https://www.linkedin.com/in/luka-vu%C4%8Dina-0171b4136/">
                            <icon name="brands/linkedin" scale="2"></icon>
                        </a>
                        <a href="mailto:vucina.luka@amplius.tech" v-b-tooltip.hover title="vucina.luka@amplius.tech">
                            <icon name="envelope-square" scale="2"></icon>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-4 my-4 team-member flex-center flex-column">
                    <div>
                        <img src="../assets/img/team/marito.png" class="img-fluid">
                    </div>
                    <h4>Marin Bevanda</h4>
                    <h5>Graphic Designer</h5>
                    <div class="team-contact flex-center">
                        <a href="https://www.linkedin.com/in/marin-bevanda-942505127/">
                            <icon name="brands/linkedin" scale="2"></icon>
                        </a>
                        <a href="mailto:bevanda.marin@amplius.tech" v-b-tooltip.hover title="bevanda.marin@amplius.tech">
                            <icon name="envelope-square" scale="2"></icon>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-4 my-4 team-member flex-center flex-column">
                    <div>
                        <img src="../assets/img/team/robito.png" class="img-fluid">
                    </div>
                    <h4>Robert Rozić</h4>
                    <h5>Software Engineer</h5>
                    <div class="team-contact flex-center">
                        <a href="https://www.linkedin.com/in/robert-rozic-53a350106/">
                            <icon name="brands/linkedin" scale="2"></icon>
                        </a>
                        <a href="mailto:rozic.robert@amplius.tech" v-b-tooltip.hover title="rozic.robert@amplius.tech">
                            <icon name="envelope-square" scale="2"></icon>
                        </a>
                    </div>
                </div>
            </b-row>
        </b-container>

        <b-container id="partners" class="mb-3">
            <h2>Partners</h2>
            <flickity :options="flickityOptions" :resize="false">
                <div class="carousel-cell flex-center row">
                    <div class="col-6 col-md-3">
                        <a href="https://nsoft.com" target="_blank">
                            <img src="../assets/img/partners/nsoft.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="http://www.hteronet.ba/" target="_blank">
                            <img src="../assets/img/partners/ht.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="http://www.mandeks.ba/" target="_blank">
                            <img src="../assets/img/partners/mandeks.png" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="carousel-cell flex-center row">
                    <div class="col-6 col-md-3">
                        <a href="https://spark.ba/" target="_blank">
                            <img src="../assets/img/partners/spark.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="https://blabladev.com/" target="_blank">
                            <img src="../assets/img/partners/blabladev.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="https://sync.ba/" target="_blank">
                            <img src="../assets/img/partners/sync.png" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="carousel-cell row flex-center">
                    <div class="col-6 col-md-3">
                        <a href="https://www.ephzhb.ba/?lang=en" target="_blank">
                            <img src="../assets/img/partners/ep.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="http://www.feal.ba/" target="_blank">
                            <img src="../assets/img/partners/feal.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="http://www.interagent.ba/" target="_blank">
                            <img src="../assets/img/partners/interagent.png" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="carousel-cell row flex-center">
                    <div class="col-6 col-md-3">
                        <a href="https://bonk.studio/" target="_blank">
                            <img src="../assets/img/partners/bonk.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="http://lamina.ba/" target="_blank">
                            <img src="../assets/img/partners/lamina.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-6 col-md-3">
                        <img src="../assets/img/partners/tankoil.png" class="img-fluid">
                    </div>
                    <div class="col-6 col-md-3">
                        <a href="https://restart.ba/" target="_blank">
                            <img src="../assets/img/partners/restart.png" class="img-fluid">
                        </a>
                    </div>
                </div>
            </flickity>
        </b-container>
    </div>
</template>

<script>
    import 'vue-awesome/icons/brands/linkedin'
    import 'vue-awesome/icons/envelope-square'
    import Icon from 'vue-awesome/components/Icon'

    export default {
        name: 'about',
        components: {
            Icon
        },
        data () {
            return {
                flickityOptions: {
                    prevNextButtons: true,
                    wrapAround: true,
                    pageDots: false,
                    duration: 4000,
                    autoPlay: true
                }
            }
        }
    }
</script>

<style lang="scss">

</style>
