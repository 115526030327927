<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column cart py-5" v-if="price !== 0">
            <b-row>
                <b-col cols="12" md="7">
                    <p>
                        <router-link to="/cart" class="dark-link">Cart&nbsp;</router-link>&nbsp;&gt;&nbsp;
                        <router-link to="/cart/customer" class="dark-link">Customer information&nbsp;</router-link>&nbsp;&gt;&nbsp;
                        <router-link to="/cart/shipping" class="dark-link">Shipping method&nbsp;</router-link>&nbsp;&gt;&nbsp;
                        <span class="cart-highlight">Payment</span>
                    </p>
                    <b-form v-on:submit.prevent="submitOrder" class="amplius-form text-left">
                        <b-form-group
                                label="Payment information">
                            <div class="row">
                                <b-form-select v-model="selected_card" :options="cards" name="cc_type" required></b-form-select>
                                <b-form-input
                                        id="payment-info"
                                        type="text"
                                        v-model="order.pan"
                                        name="pan"
                                        placeholder="Valid card number"
                                        required>
                                </b-form-input>
                                <b-form-input
                                        class="col-12 col-md-3"
                                        type="text"
                                        v-model="order.cvv"
                                        name="cvv"
                                        placeholder="CVV">
                                </b-form-input>
                                <label class="col-12 col-md-3 inline-input flex-center">Expiration date</label>
                                <div class="col-6 col-md-3 inline-input">
                                    <b-form-select
                                            type="text"
                                            v-model="order.month"
                                            :options="months"
                                            name="expiration_month"
                                            required>
                                    </b-form-select>
                                </div>
                                <div class="col-6 col-md-3 pl-2 inline-input">
                                    <b-form-select
                                            type="text"
                                            :options="years"
                                            v-model="order.year"
                                            name="expiration_year"
                                            required>
                                    </b-form-select>
                                </div>
                            </div>
                        </b-form-group>
                        <div class="row">
                            <div class="col-12 col-md-6 mt-2 flex-center flex-column">
                                <router-link to="/cart/shipping" class="dark-link">
                                    &lt; Return to shipping
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 mt-2 flex-center flex-column">
                                <button type="submit" class="amplius-btn dark-btn">
                                    Submit order
                                </button>
                            </div>
                        </div>
                    </b-form>
                </b-col>
                <b-col cols="12" md="5" class="right-panel">
                    <cart-info :cart="cart" :price="price"></cart-info>
                    <cards></cards>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    const pikPay = "https://api.amplius.tech/payment.php";

    import Cards from '../Cards';
    import CartInfo from '../CartInfo';

    export default {
        name: 'cart-payment',
        components: {
            Cards,
            CartInfo
        },
        data () {
            return {
                selected_card: null,
                cards: [
                    { value: null, text: 'Please choose' },
                    'MasterCard',
                    'Maestro',
                    'Visa'
                ],
                months: [
                    {value: null, text:'Month'}
                ],
                years: [
                    {value: null, text:'Year'}
                ]
            }
        },
        computed: {
            order: function () {
                return this.$store.getters.getOrder;
            },
            cart: function () {
                return this.$store.getters.getCart.filter(item => {
                    return item.count > 0
                });
            },
            price() {
                return this.$store.getters.totalPrice;
            }
        },
        methods: {
            submitOrder : function () {
                this.order.amount = this.price;
                this.order.expiration_date = this.order.year + this.order.month;
                this.$store.dispatch('updateOrder', this.order);
                let self = this;
                axios.post(pikPay, this.order).
                then(function (response) {
                    let data =response.data;
                    if(data.secure === "1"){
                        self.$store.dispatch('setSecure', data);
                        self.$router.push('/cart/payment/secure-3d');
                    } else if (data.error) {
                        console.log('error');
                        if(Array.isArray(data.error)){
                            self.$store.dispatch('setErrors', data.error);
                        } else {
                            self.$store.dispatch('setErrors', [data.error])
                        }
                        self.$router.push('/error')
                    } else {
                        self.$router.push('/result/' + data['response-code']);
                    }
                }).
                catch(function (error) {
                    console.log(error);
                });
            }
        },
        mounted() {
            let year = new Date().getFullYear();
            for(let i = 0; i < 20; i++) {
                let newYear = {
                    value: String((year + i)).slice(-2),
                    text: String(year + i)
                };
                this.years.push(newYear);
                if (i < 12) {
                    let newMonth = '0' + String(i + 1);
                    this.months.push(newMonth.slice(-2));
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
