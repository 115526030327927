<template>
    <div>
        <b-container fluid class="amplius-bg">
        </b-container>
        <b-container class="flex-center flex-column cart py-5" v-if="price !== 0">
            <b-row>
                <b-col cols="12" md="7">
                    <p>
                        <router-link to="/cart" class="dark-link">Cart&nbsp;</router-link>&nbsp;&gt;&nbsp;
                        <router-link to="/cart/customer" class="dark-link">Customer information&nbsp;</router-link>&nbsp;&gt;&nbsp;
                        <span class="cart-highlight">Shipping method&nbsp;</span>&nbsp;&gt;&nbsp;
                        Payment
                    </p>
                    <b-form v-on:submit.prevent="submitShipping" class="amplius-form text-left">
                        <b-form-group
                                label="Customer information"
                                label-for="customer-info">
                            <b-form-input
                                    id="customer-info"
                                    type="email"
                                    name="ch_email"
                                    :value="order.ch_email"
                                    placeholder="E-mail"
                                    disabled
                                    >
                            </b-form-input>
                            <b-form-input
                                type="text"
                                name="shipping_address"
                                :value="order.ch_address + ', ' + order.ch_zip + ' ' + order.ch_city + ', ' + order.ch_country"
                                disabled
                                >
                            </b-form-input>
                            <router-link to="/cart/customer" class="dark-link">
                                Edit
                            </router-link>
                        </b-form-group>
                        <b-form-group
                                class="mt-2"
                                label="Shipping method">
                            <b-form-select v-model="selected" :options="options" class="mb-3"></b-form-select>
                        </b-form-group>
                        <p>Delivery will be in 120 days because this is first batch of manufacturing.<br/>
                            Thank you for understanding.</p>
                        <div class="row">
                            <div class="col-12 col-md-6 mt-2 flex-center flex-column">
                                <router-link to="/cart/customer" class="dark-link">
                                    &lt; Return to customer information
                                </router-link>
                            </div>
                            <div class="col-12 col-md-6 mt-2 flex-center flex-column">
                                <button type="submit" class="amplius-btn dark-btn">
                                    Continue to payment details
                                </button>
                            </div>
                        </div>
                    </b-form>
                </b-col>
                <b-col cols="12" md="5" class="right-panel">
                    <cart-info :cart="cart" :price="price"></cart-info>
                    <cards></cards>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>

    import Cards from '../Cards';
    import CartInfo from '../CartInfo';

    export default {
        name: 'cart-shipping',
        components: {
            Cards,
            CartInfo
        },
        data () {
            return {
                selected: 'free',
                options: [
                    { value: 'free', text: 'Free standard shipping (120 days)' }
                ]
            }
        },
        computed: {
            order: function() {
              return this.$store.getters.getOrder;
            },
            cart: function () {
                return this.$store.getters.getCart.filter(item => {
                    return item.count > 0;
                });
            },
            price: function () {
                return this.$store.getters.totalPrice;
            }
        },
        methods: {
            submitShipping() {
                let info = '';
                this.cart.forEach( item => {
                    info += item.count + 'x ' + item.shortname + ', ';
                });
                this.order.order_info = info + 'S:' + this.selected;
                this.$store.dispatch('updateOrder', this.order);
                this.$router.push('/cart/payment');
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

