<template>
    <div>
        <b-container fluid class="amplius-bg" id="home">
            <b-container class="flex-center flex-column" id="cover">
                <b-row>
                    <b-col cols="12" md="6" class="text-center text-lg-left">
                        <h1>Meet RUBICO!</h1>
                        <h3>Your friend in need.</h3>
                        <p>
                            The amazing smart power pack by Amplius electronics.<br/>
                            Designed to fit in your pocket.
                        </p>
                        <!--p>
                            1st batch Preorder.<br/>
                            Get yours fast!
                        </p-->
                        <b-row class="flex-center justify-content-lg-start">
                            <!--<b-col cols="8" lg="5" class="mt-3 mt-lg-0">-->
                                <!--<a href="#" v-scroll-to="'#preorder-items'">-->
                                    <!--<button class="amplius-btn dark-btn">-->
                                        <!--Preorder NOW !-->
                                    <!--</button>-->
                                <!--</a>-->
                            <!--</b-col>-->
                            <b-col cols="8" lg="5" class="mt-3 mt-lg-0">
                                <a href="https://www.youtube.com/watch?v=dVOtRb9j-ic" target="_blank">
                                    <button class="amplius-btn">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <icon name="play-circle"/>&nbsp;Video
                                        </div>
                                    </button>
                                </a>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="6">
                        <img src="../assets/img/renders/rubico_cover.png" class="p-4 img-fluid">
                    </b-col>
                </b-row>
                <img src="../assets/img/scroll.gif" v-bind:class="{ scrolled : isScrolled}" class="scroll-gif img-fluid">
            </b-container>
        </b-container>

        <b-container id="features">
            <b-row>
                <b-col cols="12" md="6" class="feature p-4">
                    <h3>Charge in motion</h3>
                    <p>
                        Be able to keep your smartphone charged through all day - use Rubico!<br/>
                        With Rubico smart power pack, you will never find yourself in a situation where you’re holding a drained mobile phone.
                    </p>
                    <div class="learn-more d-flex" v-b-toggle.collapse1>
                        <div class="toggle-plus closed mr-2">
                            <div class="horizontal"></div>
                            <div class="vertical"></div>
                        </div>
                        <span>Learn more</span>
                    </div>
                    <b-collapse id="collapse1" class="mt-2">
                        <p>
                            Rubico smart keychain has an integrated Li-Ion battery with the capacity of 1100mAh.<br/>
                            We are using high-end technology to bring you fast-charging on supported mobile devices.<br/>
                            Charge up to 50% of battery, just in time when you need it the most.
                        </p>
                    </b-collapse>
                </b-col>
                <b-col cols="12" md="6" class="image-section">
                    <img src="../assets/img/renders/rubico_black.png" class="img-fluid p-5">
                </b-col>
            </b-row>
            <b-row class="flex-row flex-lg-row-reverse">
                <b-col cols="12" md="6" class="feature p-4">
                    <h3>Save your precious moments.</h3>
                    <p>
                        Everybody faced the problem with the lack of space on their smartphones. Luckily, we have the solution!<br/>
                        Download our mobile app, connect Rubico with your phone using the memory cable and store your data, videos and pictures in just  few clicks.
                    </p>
                    <div class="learn-more d-flex" v-b-toggle.collapse2>
                        <div class="toggle-plus closed mr-2">
                            <div class="horizontal"></div>
                            <div class="vertical"></div>
                        </div>
                        <span>Learn more</span>
                    </div>
                    <b-collapse id="collapse2" class="mt-2">
                        <p>
                            If you connect Rubico to the computer, it becomes USB Stick.<br/>
                            It is compatible with any computer, and can read any MicroSD Card.<br/>
                            Android devices which support Rubico’s additional memory need to have OTG.<br/>
                        </p>
                    </b-collapse>
                </b-col>
                <b-col cols="12" md="6" class="image-section">
                    <img src="../assets/img/renders/rubico_double.png" class="img-fluid p-5">
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6" class="feature p-4">
                    <h3>Want more? No problem...</h3>
                    <p>
                        <b>Rubico smart power pack</b> comes with a RFID chip.<br/>
                        It can be programmed as the user wishes – unlocking doors, turning on the lights, check-in cards etc.<br/>
                        Opportunities are limitless.
                    </p>
                </b-col>
                <b-col cols="12" md="6" class="image-section">
                    <img src="../assets/img/renders/rubico_white.png" class="img-fluid p-5">
                </b-col>
            </b-row>
        </b-container>

        <b-container fluid class="amplius-bg">
            <b-container id="application">
                <b-row class="row py-3">
                    <b-col cols="12" class="flex-center flex-column p-4 mb-0">
                        <h2>Rubico application</h2>
                        <p>
                            The product comes with a mobile application that allows users to easily transfer data
                            from their mobile devices to the smart power pack. The app is monitoring the amount of free memory,
                            as well as the ability to record videos and photos directly  to the smart power pack.
                        </p>
                        <a href="https://play.google.com/store/apps/details?id=tech.amplius.ampliusapp&hl=hr" target="_blank">
                            <button class="amplius-btn align-self-center flex-center"><icon name="brands/google-play"/>&nbsp;Google play</button>
                        </a>
                    </b-col>
                    <b-col cols="12" class="app-section">
                        <div class="d-flex justify-content-center">
                            <carousel-3d :space="350" :perspective="0" :autoplay="true" :autoplay-timeout="4000" id="screenshots">
                                <slide :index="0">
                                    <img src="../assets/img/ss/ss1_glare.png">
                                </slide>
                                <slide :index="1">
                                    <img src="../assets/img/ss/ss2_glare.png">
                                </slide>
                                <slide :index="2">
                                    <img src="../assets/img/ss/ss3_glare.png">
                                </slide>
                                <slide :index="3">
                                    <img src="../assets/img/ss/ss4_glare.png">
                                </slide>
                                <slide :index="4">
                                    <img src="../assets/img/ss/ss5_glare.png">
                                </slide>
                            </carousel-3d>
                            <img src="../assets/img/ss/mobile.png" class="mobile">
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <img src="../assets/img/oblak.svg" class="oblak">
        </b-container>

        <b-container id="extra">
            <b-row class="py-5 my-5">
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/warranty.png" class="img-fluid">
                    <p>12 months<br/>warranty</p>
                </b-col>
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/eu.png" class="img-fluid">
                    <p>Developed in<br/>Europe</p>
                </b-col>
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/support.png" class="img-fluid">
                    <p>24/7 customer<br/>support</p>
                </b-col>
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/dimensions.png" class="img-fluid">
                    <p>Small<br/>dimensions</p>
                </b-col>
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/mf.png" class="img-fluid">
                    <p>Multifunctional</p>
                </b-col>
                <b-col cols="6" md="4" lg="2">
                    <img src="../assets/img/extra/security.png" class="img-fluid">
                    <p>Data security</p>
                </b-col>
            </b-row>
        </b-container>

        <b-container id="preorder-items">
            <b-row class="flex-center mb-5">
                <b-col class="luka-divider"></b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="4" class="d-flex justify-content-center">
                    <div class="rubico-card flex-center flex-column">
                        <!--div class="discount">20% off</div-->
                        <div>
                            <img :src="require('../assets/img/renders/rubico_' + rubicoColor +'.png')" class="img-flud">
                        </div>
                        <h3 class="mt-2">DESCRIPTION</h3>
                        <ul>
                            <li>Rubico power pack</li>
                            <li>Special high-end charging cable</li>
                            <!--li>Shipping starts end of August (2018)</li-->
                        </ul>
                        <button class="amplius-btn dark-btn">SOLD OUT</button>
                        <template v-if="false">
                            <b-form-group>
                                <b-form-radio-group v-model="rubicoColor" :options="colors">
                                </b-form-radio-group>
                            </b-form-group>
                            <h2>Price: 34€</h2>
                            <button class="amplius-btn dark-btn"
                                    @click="addToCart('rubico_' + rubicoColor)"
                                    v-b-modal.modal-center>
                                Add to cart
                            </button>
                        </template>
                    </div>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex justify-content-center">
                    <div class="rubico-card flex-center flex-column highlight">
                        <div class="discount">10% off</div>
                        <div>
                            <img src="../assets/img/tshirt.png" class="img-flud">
                        </div>
                        <h3 class="mt-2">DESCRIPTION</h3>
                        <ul>
                            <li>Cool Amplius electronics T-shirt</li>
                            <li>Free shipping</li>
                        </ul>
                        <b-form-group>
                            <b-form-radio-group v-model="shirtSize" :options="sizes">
                            </b-form-radio-group>
                        </b-form-group>
                        <h2>Price: 15€</h2>
                        <button class="amplius-btn dark-btn"
                                @click="addToCart('tshirt' + shirtSize.toLowerCase())"
                                v-b-modal.modal-center>
                            Add to cart</button>
                    </div>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex justify-content-center">
                    <div class="rubico-card flex-center flex-column">
                        <!--div class="discount">23% off</div-->
                        <div>
                            <img src="../assets/img/renders/rubico_double.png" class="img-flud">
                        </div>
                        <h3 class="mt-2">DESCRIPTION</h3>
                        <ul>
                            <li>2x Rubico power pack</li>
                            <li>2x Special high-end charging cable</li>
                            <!--li>Shipping starts end of August (2018)</li-->
                        </ul>
                        <button class="amplius-btn dark-btn">SOLD OUT</button>
                        <template v-if="false">
                            <h2>Price: 65€</h2>
                            <button class="amplius-btn dark-btn"
                                    @click="addToCart('rubico_double')"
                                    v-b-modal.modal-center>
                                Add to cart
                            </button>
                        </template>
                    </div>
                </b-col>
                <b-modal id="modal-center" ref="modal-center" centered title="Added to cart">
                    <h2 v-if="added" class="my-4">
                        {{ added.name }}
                    </h2>
                    <img v-if="added" :src="require('../assets/img/' + added.image)" class="img-fluid mb-4">
                    <template slot="modal-footer">
                        <button class="amplius-btn grey-btn" @click="triggerBack">Back to shopping</button>
                        <button class="amplius-btn dark-btn" @click="toCart">Continue to Cart</button>
                    </template>
                </b-modal>
            </b-row>
        </b-container>

        <b-container id="statements">
            <b-row class="flex-center flex-column py-4">
                <h2>Statements</h2>
                <flickity ref="flickity" :options="flickityOptions" :resize="false" class="col-12">
                    <div class="carousel-cell">
                        <div class="flex-center flex-column">
                            <div>
                                <img src="../assets/img/statements/elena.png" class="img-fluid">
                            </div>
                            <p>
                                &quot;During my visit to Mostar, I got amazed by Amplius electronics team.
                                It's incredible that hardware product like Rubico is produced by three 20-year olds.
                                These guys should be supported by their community.&quot;
                            </p>
                            <h4>Elena Sinel, Founder of Acorn Aspirations</h4>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="flex-center flex-column">
                            <div>
                                <img src="../assets/img/statements/mike.png" class="img-fluid">
                            </div>
                            <p>
                                &quot;24 years ago Mostar (actually, whole Bosnia and Herzegovina),
                                was at war, and today local startup Amplius electronics is launching an innovative
                                battery pack! No one could possibly predict such a huge step into the future,
                                especially in the country that had a long stagnational period after the end of the war.&quot;
                            </p>
                            <h4>Mike Butcher, TechCrunch Editor</h4>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="flex-center flex-column">
                            <div>
                                <img src="../assets/img/statements/ladislav.png" class="img-fluid">
                            </div>
                            <p>
                                &quot;It is very important to support what others are doing, especially if it creates a good example.&quot;
                            </p>
                            <h4>Ladislav Jurić, Founder of Baggizmo</h4>
                        </div>
                    </div>
                </flickity>
            </b-row>
        </b-container>

    </div>
</template>

<script>
    import 'vue-awesome/icons/play-circle'
    import 'vue-awesome/icons/brands/google-play'
    import Icon from 'vue-awesome/components/Icon';

    export default {
        name: 'preorder',
        components: {
            Icon
        },
        data () {
            return {
                rubicoColor: 'black',
                shirtSize: 'L',
                flickityOptions: {
                    prevNextButtons: false,
                    wrapAround: true,
                    pageDots: true,
                    duration: 4000,
                    autoPlay: true
                },
                colors: [
                    { text:'Black', value: 'black' },
                    { text:'White', value: 'white' }
                ],
                sizes: [
                    'S', 'M', 'L', 'XL'
                ],
                added: null
            }
        },
        computed: {
            cart: function () {
                return this.$store.getters.getCart;
            },
            isScrolled: function () {
                return this.$parent.isScrolled;
            }
        },
        methods: {
            addToCart: function (item) {
                this.added = this.getItem(item);
                this.$store.dispatch('addToCart', item);
            },
            getItem: function (item_key) {
                return this.cart.find(cart_item => {
                    return cart_item.key === item_key;
                });
            },
            triggerBack: function () {
                this.$refs['modal-center'].hide();
            },
            toCart: function () {
                this.$router.push('/cart');
            }
        }
    }
</script>

<style lang="scss">
    .luka-divider {
        height: 1px;
        background: linear-gradient(to right, white, black, white);
    }
</style>
