import Vue from 'vue'
import Router from 'vue-router'
import Preorder from '@/components/Preorder'
import About from '@/components/About'
import Cart from '@/components/cart/Cart'
import CartCustomer from '@/components/cart/Customer'
import CartShipping from '@/components/cart/Shipping'
import CartPayment from '@/components/cart/Payment'
import Secure3D from '@/components/cart/Secure3D'
import Result from '@/components/cart/Result'
import Error from '@/components/cart/Error'

Vue.use(Router);

export default new Router({
    linkActiveClass: 'open active',
    mode: 'hash',
    scrollBehavior: (to) => {
       if(to.path === '/about' || to.path.match('/cart')) {
           return { y : 0 }
       }
    },
    routes: [
        {
            path: '/',
            name: 'Preorder',
            component: Preorder
        },
        {
            path: '/about',
            name: 'About',
            component: About
        },
        {
            path: '/cart',
            name: 'Cart',
            component: Cart,
        },
        {
            path: '/cart/customer',
            name: 'CartCustomer',
            component: CartCustomer,
        },
        {
            path: '/cart/shipping',
            name: 'CartShipping',
            component: CartShipping,
        },
        {
            path: '/cart/payment',
            name: 'CartPayment',
            component: CartPayment,
        },
        {
            path: '/result/:code',
            name: 'Result',
            component: Result,
        },
        {
            path: '/error',
            name: 'Error',
            component: Error,
        },
        {
            path: '/cart/payment/secure-3d',
            name: 'Secure3D',
            component: Secure3D,
        },
        {
            path: '/:scroll',
            name: 'PreorderScroll',
            component: Preorder
        }
    ]
})
