<template>
    <div>
        <div class="d-flex cards pt-3">
            <a href="https://www.mastercard.com" target="_blank">
                <img src="../assets/img/cards/mastercard.png" class="img-fluid">
            </a>
            <a href="https://www.mastercard.hr/hr-hr/consumers/find-card-products/debit-cards/maestro-debit.html" target="_blank">
                <img src="../assets/img/cards/maestro.png" class="img-fluid">
            </a>
            <a href="https://www.visaeurope.com" target="_blank">
                <img src="../assets/img/cards/visa.png" class="img-fluid">
            </a>
        </div>
        <div class="d-flex cards">
            <a href="https://www.visaeurope.com/making-payments/verified-by-visa/" target="_blank">
                <img src="../assets/img/cards/verifiedvisa.png" class="img-fluid">
            </a>
            <a href="https://www.mastercard.us/en-us/consumers/featuresbenefits/securecode.html" target="_blank">
                <img src="../assets/img/cards/securecode.png" class="img-fluid">
            </a>
        </div>
        <div class="d-flex cards">
            <a href="http://monri.com/" target="_blank">
                <img src="../assets/img/cards/monri.png" class="img-fluid">
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'cards',
        data () {
            return {
            }
        }
    }
</script>

<style lang="scss">

</style>
