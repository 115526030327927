import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        cart: [
            {
                name: 'Rubico Powerpack',
                shortname: 'RB',
                price: 3400,
                color: 'Black',
                count: 0,
                discount: '20%',
                image: 'renders/rubico_black.png',
                key: 'rubico_black'
            },
            {
                name: 'Rubico Powerpack',
                shortname: 'RW',
                price: 3400,
                color: 'White',
                count: 0,
                discount: '20%',
                image: 'renders/rubico_white.png',
                key: 'rubico_white'
            },
            {
                name: 'Rubico Double',
                shortname: 'RD',
                price: 6500,
                color: 'Black & White',
                count: 0,
                discount: '23%',
                image: 'renders/rubico_double.png',
                key: 'rubico_double'
            },
            {
                name: 'Rubico T-Shirt',
                shortname: 'TSS',
                price: 1500,
                count: 0,
                size: 'S',
                image: 'tshirt.png',
                key: 'tshirts'
            },
            {
                name: 'Rubico T-Shirt',
                shortname: 'TSM',
                price: 1500,
                count: 0,
                size: 'M',
                image: 'tshirt.png',
                key: 'tshirtm'
            },
            {
                name: 'Rubico T-Shirt',
                shortname: 'TSL',
                price: 1500,
                count: 0,
                size: 'L',
                image: 'tshirt.png',
                key: 'tshirtl'
            },
            {
                name: 'Rubico T-Shirt',
                shortname: 'TSXL',
                price: 1500,
                count: 0,
                size: 'XL',
                image: 'tshirt.png',
                key: 'tshirtxl'
            },
        ],
        order: {
            ch_country: null,
            month: null,
            year: null,
            news: false,
            currency: 'EUR'
        },
        secure: {
        },
        errors: {
        }
    },
    getters: {
        getOrder: state => {
            return state.order;
        },
        getCart: state => {
            return state.cart;
        },
        countCart: state => {
            let count = 0;
            state.cart.forEach(item => {
                count += item.count;
            });
            return count;
        },
        totalPrice: state => {
            let price = 0;
            state.cart.forEach(item => {
                price += item.count * item.price;
            });
            return price;
        },
        getSecure: state => {
            return state.secure;
        },
        getErrors: state => {
            return state.errors;
        }
    },
    mutations: {
        addToCart(state, item_key) {
            let item = state.cart.find(cart_item => {
                return cart_item.key === item_key;
            });
            item.count++;
        },
        decreaseItem(state, item_key){
            let item = state.cart.find(cart_item => {
                return cart_item.key === item_key;
            });
            if(item.count > 1) {
                item.count--;
            }
        },
        removeFromCart(state, item_key){
            let item = state.cart.find(cart_item => {
                return cart_item.key === item_key;
            });
            item.count = 0
        },
        updateOrder(state, order){
            state.order = order;
        },
        setSecure(state, secure) {
            state.secure = secure;
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        clearCart(state){
            state.cart.forEach(item => {
                item.count = 0;
            });
            state.order = {
                month: null,
                year: null,
                currency: 'EUR'
            }
        }
    },
    actions: {
        addToCart({commit}, item) {
            commit('addToCart', item)
        },
        decreaseItem({commit}, item) {
            commit('decreaseItem', item)
        },
        removeFromCart({commit}, item) {
            commit('removeFromCart', item)
        },
        updateOrder({commit}, order) {
            commit('updateOrder', order);
        },
        setSecure({commit}, secure) {
            commit('setSecure', secure);
        },
        setErrors({commit}, errors) {
            commit('setErrors', errors);
        },
        clearCart({commit}) {
            commit('clearCart');
        }
    }
});

export default store
